/*
* Percentage Based Margin/Padding is Computed on the Parent Container
* Creating an aspect ratio layout requires three layers for full
* cross browser compatibility. Especially when flexbox gets involved
*
* - A wrapping layer for size containment
* - A proxy layer for aspect ratio while preserving DOM flow
* - The actual DOM content, Guaranteed to be a perfect square with a usable width and height.
*/

.square-block {
  width: 100%;
  max-width: 50vh;
  position: relative;
  & &--inner {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
  }

  & &--inner &--content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.purple-spinner {
  color: #8400cd;
}
